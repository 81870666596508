<template>
    <div>
        <h1>Not Found</h1>
        <p>The page you requested does not exist or has moved.</p>
    </div>
</template>

<script>
    export default {
        name: "PageNotFound"
    }
</script>

<style scoped>

</style>